import React from 'react';

import LastSaved from './StatusBar/LastSaved';

const StatusBarPanel = () => {
  return (
    <>
      <LastSaved />
    </>
  );
};

export default StatusBarPanel;
